import MainLayout from "../layout";
import Page from "../page/Page";
import VideoContent from "../shared/VideoContent";
import { LinkDecor } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function VirtualMobility() {
  const { t } = useTranslation();
  let locale = i18n.language;

  let title = {
    caption: t("pages.virtual_mobility.title"),
  };

  const introductionRutubeRu = `<iframe
      title="SIIL x edX Введение"
      width="100%"
      height="100%"
      src="https://rutube.ru/play/embed/959a8b30c2d5e76d623a9951ce84e8c2/?p=wt44DfU-dbBcWS3dlrMl7w"
      frameBorder="0"
      allow="clipboard-write; autoplay"
      webkitAllowFullScreen
      mozallowfullscreen
      allowFullScreen
    ></iframe>`;
  const introductionYoutubeRu = `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/jGWzJ9aMTRQ?si=SfUPwFiKBSCpBOMj" title="SIIL x edX Введение" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
  const introductionYoutubeEng = `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/2czco7XbsWM?si=33AZnKT7ha8C2Vkz" title="SIIL x edX Introduction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
  const introductionRutubeEng = `<iframe
        title="SIIL x edX Introduction"
        width="100%"
        height="100%"
        src="https://rutube.ru/play/embed/d9d6695d38fb5df0e5fc3ecb6238fb08/?p=-OKD0IxIQIC9QovPzRthVw"
        frameBorder="0"
        allow="clipboard-write; autoplay"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>`;

  const tutorialYoutubeEng = `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/hLgkBgz2M1I?si=zr5aGsyvQXxVrbj3" title="edX x SIIL Tutorial" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
  const tutorialRutubeEng = `<iframe
        title="edX x SIIL Tutorial"
        width="100%"
        height="100%"
        src="https://rutube.ru/play/embed/3689e5628bce6b865b7cd3f2936c9d46/?p=YBRVBmFMrJIW6sylE-darg"
        frameBorder="0"
        allow="clipboard-write; autoplay"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>`;
  const tutorialYoutubeRu = `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/nkNDAqh9MKM?si=3OBnPal4sbNk3fhQ" title="edX x SIIL Руководство" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
  const tutorialRutubeRu = `<iframe
        title="edX x SIIL Руководство"
        width="100%"
        height="100%"
        src="https://rutube.ru/play/embed/5ac88fe8f056acd4f9596ae8408f6902/?p=ojC5qmriSHlY_FkmrTTndw"
        frameBorder="0"
        allow="clipboard-write; autoplay"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>`;

  const edXYoutubeEng = `<iframe width="560" height="315" src="https://www.youtube.com/embed/_t2FjWRzcG0?si=6svOusE06b7zefGJ" title="edX x SIIL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
  const edXRutubeEng = `
      <iframe
        title="edX x SIIL"
        width="720"
        height="405"
        src="https://rutube.ru/play/embed/5da4b781e29d1a52b143ee3c33f0cf88/?p=FZQ-nhV-ykb9XXIf6pTk6Q"
        frameBorder="0"
        allow="clipboard-write; autoplay"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>
    `;
  const edXYoutubeRu = `<iframe width="560" height="315" src="https://www.youtube.com/embed/GSSR1lKrGo0?si=f7avUqVATfoCKl6W" title="edX x SIIL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
  const edXRutubeRu = `<iframe
        title="edX x SIIL"
        width="720"
        height="405"
        src="https://rutube.ru/play/embed/a139769ba8340b1472c5b98b3a475c55/?p=tsaDz6v_hK8nuX3HJ2dBXA"
        frameBorder="0"
        allow="clipboard-write; autoplay"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>`;

  return (
    <MainLayout title={title}>
      <Page
        title={t("pages.virtual_mobility.title")}
        titleKey="pages.virtual_mobility.title"
        subtitle={t("pages.virtual_mobility.subtitle")}
        subtitleKey="pages.virtual_mobility.subtitle"
        isWidthSmall={true}
      >
        <div className="page__block" data-key="pages.virtual_mobility.text" dangerouslySetInnerHTML={{ __html: t("pages.virtual_mobility.text") }}></div>

        {locale !== "ru" ? (
          <VideoContent videosYoutube={[introductionYoutubeEng, tutorialYoutubeEng, edXYoutubeEng]} videosRutube={[introductionRutubeEng, tutorialRutubeEng, edXRutubeEng]} />
        ) : (
          <VideoContent videosYoutube={[introductionYoutubeRu, tutorialYoutubeRu, edXYoutubeRu]} videosRutube={[introductionRutubeRu, tutorialRutubeRu, edXRutubeRu]} />
        )}

        {locale !== "ru" ? (
          <LinkDecor link="/docs/95f3/EdX_Access_Instructions.pdf">edX Access Instructions</LinkDecor>
        ) : (
          <LinkDecor link="/docs/95f2/Instruktsiya_dlya_dostupa_k_edX.pdf">Инструкция для доступа к edX</LinkDecor>
        )}

        <LinkDecor link="/docs/95f9/Edxcatalogue.pdf">edX Course Catalogue</LinkDecor>
        <LinkDecor link="/docs/9534/Virtual_Mobility_Regulations.pdf">Virtual Mobility Regulations</LinkDecor>
      </Page>
    </MainLayout>
  );
}

export default VirtualMobility;
