import { Title } from "@vshp/ui-components";
import pageHero from "../../images/page_hero.jpg";
import cn from "classnames";

function Page({ coverImg, title, titleKey, subtitle, subtitleKey, intro, introKey, isWidthSmall = false, isWysiwyg = true, children }) {
  const pageBlockInnerClass = cn({
    wysiwyg: isWysiwyg,
    page__text: !isWidthSmall,
    "page__block-body_small": isWidthSmall,
  });

  const bg = coverImg ? coverImg : pageHero;

  return (
    <>
      <div className="page__header">
        <img className="page__header-cover" src={bg} alt="" />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key={titleKey}>
            {title}
          </Title>

          {subtitle && (
            <div className="page__header-subtitle" data-key={subtitleKey}>
              {subtitle}
            </div>
          )}

          {intro && <div className="page__header-intro" data-key={introKey} dangerouslySetInnerHTML={{ __html: intro }}></div>}
        </div>
      </div>
      <div className="page__blocks">
        <div className="page__block">
          <div className={pageBlockInnerClass}>{children}</div>
        </div>
      </div>
    </>
  );
}

export default Page;
