import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { EduquaLogoIcon, QSLogoIcon } from "../utils/svg";
import iacbeLogo from "../../images/iacbe-logo.png";
import enqaLogo from "../../images/enqa-logo.png";
import iaarLogo from "../../images/iaar-logo.png";
import mbaLogo from "../../images/mba-logo.png";
import svebLogo from "../../images/sveb-logo.png";
import { languagePathUrl } from "../utils/languagePathUrl";

function Accreditation() {
  const { currentLanguage } = useSelector((state) => state.global);
  const { t } = useTranslation();

  return (
    <div className="page__block page__block_border">
      <div className="page__block-body">
        <Title type="h2" data-key="accreditation.title">
          {t("accreditation.title")}
        </Title>
        <div className="accreditation">
          <a className="accreditation__item" href="https://www.procert.ch/zertifikate/-21742-11.html" target="_blank" rel="noopener noreferrer">
            <EduquaLogoIcon />
          </a>
          <a className="accreditation__item" href="https://www.topuniversities.com/universities/swiss-international-institute-lausanne" target="_blank" rel="noopener noreferrer">
            <QSLogoIcon />
          </a>
          <Link className="accreditation__item" to={`${languagePathUrl(currentLanguage)}/blog/posts/858-chlenstvo-siil-v-iacbe`}>
            <span>
              <img src={iacbeLogo} alt="iacbe-logo" />
            </span>
          </Link>
          <a className="accreditation__item" href="https://iaar.agency/registry/univer/318/en" target="_blank" rel="noopener noreferrer">
            <span>
              <img src={iaarLogo} alt="iaar-logo" />
            </span>
          </a>
          <a className="accreditation__item" href="/docs/914b/Swiss_International_Institute_of_Lausanne_IA__2_.pdf" target="_blank" rel="noopener noreferrer">
            <span>
              <img src={enqaLogo} alt="enqa-logo" />
            </span>
          </a>
          <a className="accreditation__item" href="https://www.topmba.com/online-mba-rankings-global?tab=indicators" target="_blank" rel="noopener noreferrer">
            <span>
              <img src={mbaLogo} alt="mba-logo" />
            </span>
          </a>
          <Link className="accreditation__item" to={`${languagePathUrl(currentLanguage)}/blog/posts/1222-siil-vstupaet-v-sveb`}>
            <span>
              <img src={svebLogo} alt="sveb-logo" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Accreditation;
