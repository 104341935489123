import MainLayout from "../layout";
import { useSelector } from "react-redux";

import { LinkDecor } from "@vshp/ui-components";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { languagePathUrl } from "../utils/languagePathUrl";
import i18n from "i18next";

function ImportantInformationStudents() {
  const { currentLanguage } = useSelector((state) => state.global);
  let locale = i18n.language;

  const { t } = useTranslation();

  let title = {
    caption: t("pages.important_information_for_students.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.important_information_for_students.title")} titleKey="pages.important_information_for_students.title">
        <LinkDecor
          link={locale !== "ru" ? "/docs/9011/Presentation_ENG_2023.pdf" : "/docs/9017/SIIL_Main_Presentation_2023_RU.pdf"}
          data-key="pages.important_information_for_students.link_title.main_pres"
        >
          {t("pages.important_information_for_students.link_title.main_pres")}
        </LinkDecor>
        <LinkDecor
          link={locale !== "ru" ? "/docs/965b/Guidelines_for_Students_on_Writing_Courseworks__BA_and_MA_Theses.pdf" : "/docs/965d/Rukovodstvo_dlya_studentov_po_vypolneniyu_rabot.pdf"}
          data-key="pages.important_information_for_students.link_title.guidelines_for_writing"
        >
          {t("pages.important_information_for_students.link_title.guidelines_for_writing")}
        </LinkDecor>
        <LinkDecor
          link={
            locale !== "ru"
              ? "/docs/9023/Academic_Integrity_and_Academic_misconduct_terms_and_procedures.pdf"
              : "/docs/9096/Akademicheskaya_chestnost_i_akademicheskie_narusheniya-_terminy_i_protsedury__1_.pdf"
          }
          data-key="pages.important_information_for_students.link_title.academic_integrity"
        >
          {t("pages.important_information_for_students.link_title.academic_integrity")}
        </LinkDecor>
        <LinkDecor
          link={locale !== "ru" ? "/docs/901e/Appendix_A_to_RABA.pdf" : "/docs/90a2/Prilozhenie_A_k_Pravilam_zachisleniya_na_programmy_bakalavriata_.pdf"}
          data-key="pages.important_information_for_students.link_title.appendix"
        >
          {t("pages.important_information_for_students.link_title.appendix")}
        </LinkDecor>
        <LinkDecor
          link={locale !== "ru" ? "/docs/901f/Appendix_B_to_RABA.pdf" : "/docs/90a3/Prilozhenie_B_k_Pravilam_zachisleniya_na_programmy_bakalavriata_.pdf"}
          data-key="pages.important_information_for_students.link_title.appendix_b"
        >
          {t("pages.important_information_for_students.link_title.appendix_b")}
        </LinkDecor>
        {locale !== "ru" ? (
          <LinkDecor link="/docs/9591/Catalogue.pdf">Catalogue of Study Programmes 2024</LinkDecor>
        ) : (
          <LinkDecor link="/docs/95a1/Katalog_uchebnyh_programm.pdf">Каталог учебных программ 2024</LinkDecor>
        )}
        <LinkDecor
          link="/docs/9690/Dogovor_ob_okazanii_platnyh_obrazovatelnyh_uslug_po_programmam_vysshego_obrazovaniya_Swiss_International_Institute_Lausanne___280-1344-4106300-0325.pdf"
          data-key="pages.important_information_for_students.link_title.contract"
        >
          {t("pages.important_information_for_students.link_title.contract")}
        </LinkDecor>
        <Link className="link_decor" to={`${languagePathUrl(currentLanguage)}/agreement`} data-key="pages.important_information_for_students.link_title.data_processing_agreement">
          {t("pages.important_information_for_students.link_title.data_processing_agreement")}
        </Link>
        <LinkDecor link="/docs/9602/Prezentatsiya_diploma.pdf" data-key="pages.important_information_for_students.link_title.diploma">
          {t("pages.important_information_for_students.link_title.diploma")}
        </LinkDecor>

        <LinkDecor
          link={locale !== "ru" ? "/docs/9022/Plagiarism_Policy.pdf" : "/docs/909d/Politika_v_otnoshenii_plagiata.pdf"}
          data-key="pages.important_information_for_students.link_title.plagiarism"
        >
          {t("pages.important_information_for_students.link_title.plagiarism")}
        </LinkDecor>
        <LinkDecor
          link={locale !== "ru" ? "/docs/8fa9/Rules_of_Admission_Bachelor_Programmes.pdf" : "/docs/90a4/Pravila_zachisleniya_na_programmy_bakalavriata.pdf"}
          data-key="pages.important_information_for_students.link_title.rules_bachelor"
        >
          {t("pages.important_information_for_students.link_title.rules_bachelor")}
        </LinkDecor>
        <LinkDecor
          link={locale !== "ru" ? "/docs/8faa/Rules_of_Admission_Master_Programmes.pdf" : "/docs/90a5/Pravila_zachisleniya_na_programmy_magistratury.pdf"}
          data-key="pages.important_information_for_students.link_title.rules_master"
        >
          {t("pages.important_information_for_students.link_title.rules_master")}
        </LinkDecor>
        <LinkDecor
          link={locale !== "ru" ? "/docs/9020/Student_Complaints_Procedures.pdf" : "/docs/90b0/Protsedura_podachi_zhalob_studentami__1_.pdf"}
          data-key="pages.important_information_for_students.link_title.complaints_procedures"
        >
          {t("pages.important_information_for_students.link_title.complaints_procedures")}
        </LinkDecor>
        <LinkDecor
          link={locale !== "ru" ? "/docs/9021/Student_complaints_template.pdf" : "/docs/90a6/Protsedura_podachi_zhalob_studentami_-_forma_zhaloby.pdf"}
          data-key="pages.important_information_for_students.link_title.complaints_form"
        >
          {t("pages.important_information_for_students.link_title.complaints_form")}
        </LinkDecor>
        <LinkDecor
          link="https://docs.google.com/forms/d/e/1FAIpQLSfRn9P34KwzoP9ZnMfJV5rCHeA1stjhL3FP7ZDr0CKu9UVe0w/viewform"
          data-key="pages.important_information_for_students.link_title.satisfaction_form"
        >
          {t("pages.important_information_for_students.link_title.satisfaction_form")}
        </LinkDecor>
        <LinkDecor
          link={locale !== "ru" ? "/docs/8fa7/Study_Regulations_for_Bachelor_Programmes_PDF.pdf" : "/docs/90a7/Pravila_obucheniya_na_programmah_bakalavriata.pdf"}
          data-key="pages.important_information_for_students.link_title.study_regulations"
        >
          {t("pages.important_information_for_students.link_title.study_regulations")}
        </LinkDecor>
        <LinkDecor
          link={locale !== "ru" ? "/docs/8fa8/Study_Regulations_for_Master_Programmes.pdf" : "/docs/90a8/Pravila_obucheniya_na_programmah_magistratury.pdf"}
          data-key="pages.important_information_for_students.link_title.study_regulations_master"
        >
          {t("pages.important_information_for_students.link_title.study_regulations_master")}
        </LinkDecor>
        <LinkDecor link="https://siil.ch/docs/8fdf/Students___handbook.pdf" data-key="pages.important_information_for_students.link_title.handbook">
          {t("pages.important_information_for_students.link_title.handbook")}
        </LinkDecor>
        <LinkDecor
          link={
            locale !== "ru"
              ? "/docs/9024/Technical_requirements_for_online_access_from_online_versions_in_viewing_mode.pdf"
              : "/docs/90b1/Tehnicheskie_trebovaniya_k_kompyuteru_dlya_onlayn_dostupa_iz_setevyh_.pdf"
          }
          data-key="pages.important_information_for_students.link_title.technical"
        >
          {t("pages.important_information_for_students.link_title.technical")}
        </LinkDecor>
        <LinkDecor
          link={locale !== "ru" ? "/docs/8fac/Tuition_fees_regulations_Master.pdf" : "/docs/90a9/Pravila_oplaty_obucheniya.pdf"}
          data-key="pages.important_information_for_students.link_title.tuition"
        >
          {t("pages.important_information_for_students.link_title.tuition")}
        </LinkDecor>
        {locale !== "ru" ? (
          <LinkDecor link="/docs/9655/Declaration-originality_SIIL.pdf">Declaration of originality</LinkDecor>
        ) : (
          <LinkDecor link="/docs/9656/Deklaratsiya-ob-originalnosti_SIIL.pdf">Декларация об оригинальности</LinkDecor>
        )}
      </Page>
    </MainLayout>
  );
}

export default ImportantInformationStudents;
