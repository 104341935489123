import { useState } from "react";
import { Switch } from "@vshp/ui-components";
// import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { setGetParam, getGetParam } from "../../actions/global";

const VideoContent = (props) => {
  const { t } = useTranslation();

  const videoSettingsFlag = getGetParam("isYouTubeOn") === "true";
  const [isVideoYouTube, setStateVideoYouTube] = useState(videoSettingsFlag || false);

  const onChangeSwitch = () => {
    setGetParam("isYouTubeOn", !isVideoYouTube, 20 * 365);
    setStateVideoYouTube(!isVideoYouTube);
  };

  return (
    <>
      {/* {props.title.length > 0 && <Title type="h2">{props.title}</Title>} */}
      {isVideoYouTube
        ? props.videosYoutube.map((video, key) => (
            <div className="page__block" key={key}>
              <div className="video" dangerouslySetInnerHTML={{ __html: video }}></div>
            </div>
          ))
        : props.videosRutube.map((video, key) => (
            <div className="page__block" key={key}>
              <div className="video" dangerouslySetInnerHTML={{ __html: video }}></div>
            </div>
          ))}

      <div className="video__settings" data-key="plan.video.settings_text">
        {t("plan.video.settings_text")}
        <Switch defaultChecked={isVideoYouTube} onChange={onChangeSwitch} />
      </div>
    </>
  );
};

export default VideoContent;
