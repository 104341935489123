import MainLayout from "../layout";
import { LinkDecor } from "@vshp/ui-components";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function StudyPlans() {
  const { t } = useTranslation();
  let locale = i18n.language;

  let title = {
    caption: t("pages.study_plans.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.study_plans.title")} titleKey="pages.study_plans.title">
        {locale !== "ru" ? (
          <>
            <LinkDecor link="/docs/9591/Catalogue.pdf">Catalogue of Study Programmes 2024</LinkDecor>
            <LinkDecor link="/docs/9012/Catalogue_of_Study_Programmes.pdf">Catalogue of Study Programmes 2023</LinkDecor>
            <LinkDecor link="/docs/9592/Study_Plan_for_the_Bachelor_s_Program_in_Event_Management.pdf">Bachelor of Arts in Event Management - 180 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/9597/Study_Plan_for_the_Bachelor_s_Program_in_Luxury_Brand_Management.pdf">Bachelor of Arts in Luxury Brand Management - 180 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/9598/Study_Plan_for_the_Bachelor_s_Program_in_Project_Management_-_180.pdf">Bachelor of Arts in Project Management - 180 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/9599/Study_Plan_for_the_Bachelor_s_Program_in_Project_Management_-_240.pdf">Bachelor of Arts in Project Management - 240 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/9593/Study_Plan_for_the_Bachelor_s_Program_in_Information_Systems_and_Technology_-_180.pdf">
              Bachelor of Science in Information Systems and Technology - 180 ECTS (RU)
            </LinkDecor>
            <LinkDecor link="/docs/9594/Study_Plan_for_the_Bachelor_s_Program_in_Information_Systems_and_Technology_-_240.pdf">
              Bachelor of Science in Information Systems and Technology - 240 ECTS (RU)
            </LinkDecor>
            <LinkDecor link="/docs/9595/Study_Plan_for_the_Bachelor_s_Program_in_Internet_Marketing_-_180.pdf">Bachelor of Science in Internet Marketing - 180 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/9596/Study_Plan_for_the_Bachelor_s_Program_in_Internet_Marketing_-_240.pdf">Bachelor of Science in Internet Marketing - 240 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/959a/Study_Plan_for_the_Bachelor_s_Program_in_Web_Design_-_180.pdf">Bachelor of Science in Web Design - 180 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/959b/Study_Plan_for_the_Bachelor_s_Program_in_Web_Design_-_240.pdf">Bachelor of Science in Web Design - 240 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/95a0/Study_Plan_for_the_Master_s_Program_in_International_Relations.pdf">Master of Arts in International Relations - 90 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/959d/Study_Plan_for_the_Master_s_Program_in_Business_Administration_-_90.pdf">Master of Business Administration - 90 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/959c/Study_Plan_for_the_Master_s_Program_in_Business_Administration_-_90__ENG_.pdf">Master of Business Administration - 90 ECTS (ENG)</LinkDecor>
            <LinkDecor link="/docs/959f/Study_Plan_for_the_Master_s_Program_in_Business_Administration_-_120.pdf">Master of Business Administration - 120 ECTS (RU)</LinkDecor>
            <LinkDecor link="/docs/959e/Study_Plan_for_the_Master_s_Program_in_Business_Administration_-_120__ENG_.pdf">Master of Business Administration - 120 ECTS (ENG)</LinkDecor>
          </>
        ) : (
          <>
            <LinkDecor link="/docs/95a1/Katalog_uchebnyh_programm.pdf">Каталог учебных программ 2024</LinkDecor>
            <LinkDecor link="/docs/901d/Katalog_uchebnyh_programm.pdf">Каталог учебных программ 2023</LinkDecor>
            <LinkDecor link="/docs/95a2/Uchebnyy_plan_po_programme_Bakalavriata__Event-menedzhment__180.pdf">Бакалавриат по направлению event менеджмент - 180 ECTS</LinkDecor>
            <LinkDecor link="/docs/95a3/Uchebnyy_plan_po_programme_Bakalavriata__Brend-menedzhment_tovarov_i_uslug_klassa_lyuks__180.pdf">
              Бакалавриат по направлению бренд-менеджмент - 180 ECTS
            </LinkDecor>
            <LinkDecor link="/docs/95aa/Uchebnyy_plan_po_programme_Bakalavriata__Proektnyy_menedzhment__180.pdf">Бакалавриат по направлению проектный менеджмент - 180 ECTS</LinkDecor>
            <LinkDecor link="/docs/95ab/Uchebnyy_plan_po_programme_Bakalavriata__Proektnyy_menedzhment__240.pdf">Бакалавриат по направлению проектный менеджмент - 240 ECTS</LinkDecor>
            <LinkDecor link="/docs/95a8/Uchebnyy_plan_po_programme_Bakalavriata__Informatsionnye_sistemy_i_tehnologii__180.pdf">
              Бакалавриат по направлению информационные системы и технологии - 180 ECTS
            </LinkDecor>
            <LinkDecor link="/docs/95a9/Uchebnyy_plan_po_programme_Bakalavriata__Informatsionnye_sistemy_i_tehnologii__240.pdf">
              Бакалавриат по направлению информационные системы и технологии - 240 ECTS
            </LinkDecor>
            <LinkDecor link="/docs/95a6/Uchebnyy_plan_po_programme_Bakalavriata__Internet-marketing__180.pdf">Бакалавриат по направлению интернет маркетинг - 180 ECTS</LinkDecor>
            <LinkDecor link="/docs/95a7/Uchebnyy_plan_po_programme_Bakalavriata__Internet-marketing__240.pdf">Бакалавриат по направлению интернет маркетинг - 240 ECTS</LinkDecor>
            <LinkDecor link="/docs/95a4/Uchebnyy_plan_po_programme_bakalavriata__Veb-dizayn__180.pdf">Бакалавриат по направлению веб-дизайн - 180 ECTS</LinkDecor>
            <LinkDecor link="/docs/95a5/Uchebnyy_plan_po_programme_bakalavriata__Veb-dizayn__240.pdf">Бакалавриат по направлению веб-дизайн - 240 ECTS</LinkDecor>
            <LinkDecor link="/docs/95ae/Uchebnyy_plan_po_programme_Magistratury__Magistr_mezhdunarodnyh_otnosheniy_.pdf">Магистратура по направлению международные отношения - 90 ECTS</LinkDecor>
            <LinkDecor link="/docs/95ac/Uchebnyy_plan_po_programme_Magistratury__Magistr_delovogo_administrirovaniya_v_upravlenii_biznesom__90.pdf">
              Магистратура по направлению делового администрирования - 90 ECTS
            </LinkDecor>
            <LinkDecor link="/docs/95ad/Uchebnyy_plan_po_programme_Magistratury__Magistr_delovogo_administrirovaniya_v_upravlenii_biznesom__120.pdf">
              Магистратура по направлению делового администрирования - 120 ECTS
            </LinkDecor>
          </>
        )}
      </Page>
    </MainLayout>
  );
}

export default StudyPlans;
